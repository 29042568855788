@import-normalize;

// three-dots
// @import '~three-dots/sass/three-dots';  // import all

// @import '~three-dots/sass/variables'; // import variables

// custom variables
$dot-width: 0.3em !default;
$dot-height: 0.3em !default;
$dot-radius: $dot-width/2 !default;

$dot-color: #BDADAD !default;
$dot-bg-color: $dot-color !default;
$dot-before-color: $dot-color !default;
$dot-after-color: $dot-color !default;

$dot-spacing: 6px+6px/2 !default;

@import '~three-dots/sass/mixins';
@import '~three-dots/sass/dot-typing';

// start writing

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


// Grid
$colCount: 20;
$rowCount: 40;

.grid {
  display: grid;
}

.col-end {
  grid-row-start: auto;
  grid-column-end: -1;
}

.col-full {
  grid-column: 1 / -1;
}

@for $i from 1 through $colCount {
  .col-s#{$i} {
    grid-column-start: $i;
    grid-row-end: auto;
  }

  .col-#{$i} {
    grid-column-end: span #{$i};
  }
}

@for $i from 1 through $rowCount {
  .row-s#{$i} {
    grid-row-start: $i;
  }

  .row-#{$i} {
    grid-row-end: span #{$i};
  }
}

// flex
.flex {
  display: flex;

  &.align-c-c {
    justify-content: center;
    align-items: center;
  }

  &.align-n-c {
    align-items: center;
  }

  &.align-s-e {
    justify-content: flex-start;
    align-items: flex-end;
  }
}

// text
.align-c {
  text-align: center;
}

// image
.img-response {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

// btn

.btn {
  display: inline-block;
  border-radius: 0.2em;
  padding: 0.4em 0.4em;
  min-width: 7.5em;
  text-align: center;
  text-decoration: none;
  border: none;
}

.btn.primary {
  color: #FFFFFF;
  background-color: #68A8B7;
}

.fz-xs {
  font-size: 0.6em;
}

.fz-sm {
  font-size: 0.8em;
}

.fz-md {
  font-size: 1.2em;
}

.fz-lg {
  font-size: 1.4em;
}

// form
.from-container {
  border-radius: 1em;
  margin: 0 2em;
  padding: 1em;
  padding: 1em;
  background-color: rgba($color: #FFFFFF, $alpha: 0.6);
}

.from-container.horizontal-flex {
  .form-group {
    display: flex;
    margin-bottom: 20px;

    &>label {
      flex: 0 0 auto;
      margin-right: .4em;
      padding: .2em 0;
      font-size: 1em;
      line-height: 1.4em;
    }

    &>.form-field {
      flex: 1 1 auto;
    }
  }
  .form-control {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    padding: .2em .4em;
    font-size: 1em;
    line-height: 1.4em;
  }

  .error-message {
    font-size: .8em;
  }
}

.btn-submit {
  color: #FFFFFF;
  background-color: #96D6D4;
}

.error-message {
  color: #eb9697;
  margin: 0;
  padding: .32em;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  -webkit-text-fill-color: #000;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-select: none;
}
